body {
    margin: 0;
    font-family: 'Poppins',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #eeeeee;
    max-width: 100%;
    overflow-x: hidden !important;
}

code {
    font-family: 'Poppins', sans-serif;
}
